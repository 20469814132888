import React, { useState, useEffect } from "react";
import { Button, Form, Col, Card } from "react-bootstrap";
import CheckoutSteps from "../components/CheckoutSteps";
import {
  SmallButtonStyle,
  MediumButtonStyle,
} from "../components/ButtonStyle";
import { useTranslation } from "react-i18next";
import addressService from "../services/address.service";
import cartService from "../services/cart.service";
import mathService from "../services/math.service";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCart } from "../actions/cart.action";

const AddressForm=({type, newAddress, setNewAddress, checkPincode, cancelNewAddress, SaveAddress})=>{
  const [error, setError] = useState({})
  const { t } = useTranslation();
  const InputStyle = {
    border: 0,
    boxShadow: "none",
    borderBottom: "2px solid #5DA7CA",
    borderRadius: "0px",
    paddingLeft: 0,
    background: "rgba(244, 246, 251, 1)",
  };

  const submit=()=>{
    const validationError = validation()
    setError(validationError)

     if(Object.keys(validationError).length===0){
      console.log('save address')
      SaveAddress(type)
     }
  }

  const validation=()=>{
    const errors = {}
    if (!newAddress?.full_name) {
      errors.full_name = 'Full name is required';
    }
  
    if (!newAddress?.address) {
      errors.address = 'Address is required';
    }
  
    if (!newAddress?.city) {
      errors.city = 'City is required';
    }
  
    if (!newAddress?.pincode) {
      errors.pincode = 'Pincode is required';
    } else if (!/^\d{6}$/.test(newAddress?.pincode)) {
      errors.pincode = 'Pincode must be 6 digits';
    }
  
    if (!newAddress?.apartment) {
      errors.apartment = 'Apartment is required';
    }
  
    if (!newAddress?.state) {
      errors.state = 'State is required';
    }
  
    if (!newAddress?.country) {
      errors.country = 'Country is required';
    }
  
    if (!newAddress?.mobile) {
      errors.mobile = 'Mobile number is required';
    } else if (!/^\d{10}$/.test(newAddress?.mobile)) {
      errors.mobile = 'Mobile number must be 10 digits';
    }
  
    return errors;
  }

  return(
    <div style={{background:'rgba(244, 246, 251, 1)', padding:10, margin:10, borderRadius:5}}>
       <h4>Add new {type} address</h4>
       <Form onSubmit={validation}>
          <Form.Group controlId="fullname">
            <Form.Control
              style={InputStyle}
              required
              type="text"
              placeholder={t("FullName")}
              value={newAddress?.full_name}
              onChange={(e) => setNewAddress(prevState=>({...prevState, full_name:e.target.value}))}
            />
            {error?.full_name && <text style={{fontSize:12, color:'orange'}}>{error?.full_name}</text>}
          </Form.Group>
          <Form.Group controlId="address">
            <Form.Control
              style={InputStyle}
              required
              type="text"
              placeholder={t("Address")}
              value={newAddress?.address}
              onChange={(e) => setNewAddress(prevState=>({...prevState, address:e.target.value}))}
            />
            {error?.address && <text style={{fontSize:12, color:'orange'}}>{error?.address}</text>}
          </Form.Group>

          <Form.Group controlId="AppartmentName.">
            <Form.Control
              style={InputStyle}
              required
              type="text"
              value={newAddress?.apartment}
              placeholder={t("AppartLandmark")}
              onChange={(e) =>setNewAddress(prevState=>({...prevState, apartment:e.target.value}))}
            />
            {error?.apartment && <text style={{fontSize:12, color:'orange'}}>{error?.apartment}</text>}
          </Form.Group>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Control
                style={InputStyle}
                required
                type="text"
                value={newAddress?.city}
                placeholder={t("CityTown")}
                onChange={(e) =>setNewAddress(prevState=>({...prevState, city:e.target.value}))}
              />
              {error?.city && <text style={{fontSize:12, color:'orange'}}>{error?.city}</text>}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridState">
              <Form.Control
                as="select"
                required
                value={newAddress?.state}
                style={InputStyle}
                onChange={(e) =>setNewAddress(prevState=>({...prevState, state:e.target.value}))}
              >
                <option>{'Chhattisgarh'}</option>
              </Form.Control>
              {error?.state && <text style={{fontSize:12, color:'orange'}}>{error?.state}</text>}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridZip">
              <Form.Control
                required
                type="numeric"
                maxLength="6"
                style={InputStyle}
                value={newAddress?.pincode}
                placeholder={t("Pincode")}
                onChange={(e) =>(type==='shipping' && e.target.value?.length===6 && checkPincode(e.target.value), setNewAddress(prevState=>({...prevState, pincode:e.target.value})))}
              />
              {error?.pincode && <text style={{fontSize:12, color:'orange'}}>{error?.pincode}</text>}
            </Form.Group>
          </Form.Row>

          <Form.Group as={Col} md="4" controlId="Mobile">
            <Form.Control
              required
              type="numeric"
              maxLength="10"
              style={InputStyle}
              value={newAddress?.mobile}
              placeholder={t("MobileNumber")}
              onChange={(e) =>setNewAddress(prevState=>({...prevState, mobile:e.target.value}))}
            />
            {error?.mobile && <text style={{fontSize:12, color:'orange'}}>{error?.mobile}</text>}
          </Form.Group>
          <Form.Group controlId="Country">
            <Form.Control
              as="select"
              required
              value={newAddress?.country}
              style={InputStyle}
              onChange={(e) =>setNewAddress(prevState=>({...prevState, country:e.target.value}))}
            >
              <option>India</option>
            </Form.Control>
            {error?.country && <text style={{fontSize:12, color:'orange'}}>{error?.country}</text>}
          </Form.Group>
          <div style={{display:'flex', width:'100%', justifyContent:'right'}}>
            <Button style={{...SmallButtonStyle}} onClick={()=>cancelNewAddress(type)}>cancel</Button>
            <Button style={{...SmallButtonStyle}} onClick={()=>submit()}>save</Button>
          </div>
      </Form>
    </div>
  )
}

function ShippingScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [userShippingAddress, setUserShippingAddress] = useState();
  const { cart } = useSelector(state=>state.carts)
  const [addNewShippingAddress, setAddNewShippingAddress] = useState(false)
  const [addNewBillingAddress, setAddNewBillingAddress] = useState(false)
  const [sameAsShipping, setSameAsShipping] = useState(true)
  const [isAgree, setIsAgree] = useState(false)
  const [selectedShippingAddress, setSelectedShippingAddress] = useState()
  const [selectedBillingAddress, setSelectedBillingAddress] = useState()
  const [shippingCharge, setShippingCharge] = useState();
  const [newShippingAddress, setNewShippingAddress] = useState({full_name:'', address:'', city:'', pincode:'', apartment:'', state:'Chhattisgarh', country:'India', mobile:''})
  const [newBillingAddress, setNewBillingAddress] = useState({full_name:'', address:'', city:'', pincode:'', apartment:'', state:'Chhattisgarh', country:'India', mobile:''})

  //cart amount 
  const totalSellingAmount = cart && mathService.totalCartAmount(cart).totalSellingAmount
  const CustomStyle = {
    cursorStyle: {
      cursor:
        shippingCharge===null || isAgree===false
          ? "not-allowed"
          : "pointer",
    },
    shippingChargeStyle: {
      fontSize: "20px",
      color: "rgba(29, 49, 120, 1)",
      fontWeight: "bold",
    },
    shippingChargeStyle2: {
      fontSize: "15px",
      color: "orange",
    },
    shippingChargeStyle3: {
      fontSize: "20px",
      color: "rgba(29, 49, 120, 1)",
    },

    content: {
      width: "80%",
      textTransform: "capitalize",
      color: "black",
    },

    textDecoration: {
      textTransform: "capitalize",
    },
  };

  const shippingAddressHandler=(addressId)=>{
      setSelectedShippingAddress(addressId)
      sameAsShipping && setSelectedBillingAddress(addressId)
  };

  const billingAddressHandler=(addressId)=>{
    setSelectedBillingAddress(addressId)
  };

  const checkPincode=(pincode)=>{
     (pincode?.length===6) && addressService.getDeliveryCharge(pincode)
                   .then((res)=>{
                    setShippingCharge(res.data.shipping_charge)
                   })
                   .catch((error)=>{
                    console.log("getDeliveryCharge error :: ",error)
                    setShippingCharge()
                   })
        return null
  }

  const getAddress=()=>{
    addressService.getShippingAddressByUser()
                  .then((res)=>{
                    setUserShippingAddress(res.data)
                  })
                  .catch((error)=>{
                    console.log(error)
                  })
  }

  const getCartProduct=()=>{
    dispatch(getCart())
  }


  const SaveAddress=(type)=>{
    if(type==='shipping'){
      let data={
        full_name : newShippingAddress.full_name,
        address : newShippingAddress.address,
        apartment : newShippingAddress.apartment,
        city : newShippingAddress.city,
        state : newShippingAddress.state,
        mobile : newShippingAddress.mobile,
        pincode : newShippingAddress.pincode,
        type : type
    }
    addressService.addAddressHandler(data)
                  .then((res)=>{
                    console.log('res.data :: ', res.data)
                    getAddress()
                    setAddNewShippingAddress(false)
                  })
                  .catch((error)=>{
                    console.log(error)
                  })
    }
    if(type==='billing'){
      console.log(newBillingAddress)
      let data={
        full_name : newBillingAddress.full_name,
        address : newBillingAddress.address,
        apartment : newBillingAddress.apartment,
        city : newBillingAddress.city,
        state : newBillingAddress.state,
        mobile : newBillingAddress.mobile,
        pincode : newBillingAddress.pincode,
        type : type
    }
      addressService.addAddressHandler(data)
                  .then((res)=>{
                    getAddress()
                    setAddNewBillingAddress(false)
                  })
                  .catch((error)=>{
                    console.log(error)
                  })
    }
  }

  const cancelNewAddress=(type)=>{
    if(type==='shipping'){
      userShippingAddress?.filter(address=>address.type==='shipping')?.length>0 && setAddNewShippingAddress(false)
    }
    if(type==='billing'){
      userShippingAddress?.filter(address=>address.type==='billing')?.length>0 && setAddNewBillingAddress(false)
    }
  }

  const submit=(e)=>{
    e.preventDefault()
    let totalAmount = shippingCharge && (Number(totalSellingAmount)+Number(shippingCharge)).toFixed(2)
    navigate('/payment', {state : {addressId:{shipping_id : selectedShippingAddress, billing_id : selectedBillingAddress}, shippingCharge : shippingCharge, totalAmount : totalAmount}})
  }

  useEffect(() => {
    !userShippingAddress && getAddress()
    !cart && getCartProduct()
  }, [cart, userShippingAddress]);

  // -----------------components--------------------------
  const ShippingAddressCard=()=>{
    return(
      <div style={{background:'rgba(244, 246, 251, 1)', padding:10, margin:10, borderRadius:5}}>
        <h4>Shipping Address</h4>
        {
          userShippingAddress?.filter(address=>address.type==='shipping')?.map((address, index) => (
            <Card key={index} className="my-4">
              <Col
                id={"ship_add_col_id_" + index}
                className="my-4"
              >
                  <div style={CustomStyle.content}>
                    <strong>{address.full_name}</strong>
                    <br></br>
                    <text>{address.apartment},</text>
                    <text>{address.address},</text>
                    <text>{address.city},</text>
                    <text>{address.pincode},</text>
                    <text>{address.state.toLowerCase()},</text>
                    <text>{address.country}</text>
                    <br></br>
                    <text>{address.mobile}</text>
                  </div>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{ position: "absolute", bottom: 0, right: 30 }}
                    >
                      <Form.Check
                        type="radio"
                        required
                        id={"selectedShippingAddress_" + index}
                        checked={address.id===selectedShippingAddress}
                        onChange={()=>(shippingAddressHandler(address.id), checkPincode(address.pincode))}
                        name="selectedShippingAddress"
                        label="Select"
                      />
                    </div>
                  </div>
              </Col>
            </Card>
            ))
        }
        <Button
         style={{...MediumButtonStyle}}
         onClick={()=>setAddNewShippingAddress(true)}
        >
          Add new address
        </Button>
      </div>
    )
  }

  const BillingAddressCard=()=>{
    return(
      <div style={{background:'rgba(244, 246, 251, 1)', padding:10, margin:10, borderRadius:5}}>
        <h4>Billing Address</h4>
        {
          userShippingAddress.filter(address=>address.type==='billing')?.map((address, index) => (
            <Card key={index} className="my-4">
              <Col
                id={"bill_add_col_id_" + index}
                className="my-4"
              >
                  <div style={CustomStyle.content}>
                    <strong>{address.full_name}</strong>
                    <br></br>
                    <text>{address.apartment},</text>
                    <text>{address.address},</text>
                    <text>{address.city},</text>
                    <text>{address.pincode},</text>
                    <text>{address.state.toLowerCase()},</text>
                    <text>{address.country}</text>
                    <br></br>
                    <text>{address.mobile}</text>
                  </div>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{ position: "absolute", bottom: 0, right: 30 }}
                    >
                      <Form.Check
                        type="radio"
                        required
                        id={"selectedBillingAddress_" + index}
                        checked={address.id===selectedBillingAddress}
                        onChange={()=>billingAddressHandler(address.id)}
                        name="selectedBillingAddress"
                        label="Select"
                      />
                    </div>
                  </div>
              </Col>
            </Card>
            ))
        }
        <Button
        style={{...MediumButtonStyle}}
         onClick={()=>setAddNewBillingAddress(true)}
        >
          Add new address
        </Button>
      </div>
    )
  }

  const CartDetail=()=>{
    return(
      <div style={{background:'rgba(244, 246, 251, 1)', padding:10, margin:10, borderRadius:5}}>
          {
            cart?.map((item, index)=>{
              // let imageURL = 'https://ik.imagekit.io/oet53wxls7nq/'+(item?.product_variation?.media?.[0]?.image?.image)?.replace(/\/media\/(marketplace\/)?/g, "")
              let imageURL = (item?.product_variation?.media?.[0]?.image?.image)?.replace(/\/media\/(marketplace\/)?/g, "")
             return(
              <div key={index} style={{display:'flex',alignItems:'center'}}>
                <img src={imageURL} style={{aspectRatio:1, objectFit:'cover', height:80, width:80, margin:10, borderRadius:5}} alt="product"/>
                <text style={{width:'30%', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap' }}>
                  {item?.product_variation?.product?.name}
                </text>
                <text>{item?.product_variation?.stock?.selling_price}</text>
                <text> X {item?.quantity}</text>
              </div>
             )
            })
          }
      </div>
    )
  }

  const Subtotal=()=>{
    return(
      <div style={{background:'rgba(244, 246, 251, 1)', padding:10, margin:10, borderRadius:5}}>
        <div style={{display:'flex',alignItems:'center', margin:10}}>
          <text style={{width:'50%'}}>
            Subtotal
          </text>
          <text style={{width:'50%'}}>
            {totalSellingAmount}
          </text>
        </div>
      </div>
    )
  }

  const ShippingCharge=({shippingCharge})=>{
    return(
      <div style={{background:'rgba(244, 246, 251, 1)', padding:10, margin:10, borderRadius:5}}>
        <div style={{display:'flex',alignItems:'center', margin:10}}>
          <text style={{width:'50%'}}>
            Shipping Charge
          </text>
          <text style={{width:'50%'}}>
            {shippingCharge ? shippingCharge.toFixed(2) : 'Please select address'}
          </text>
        </div>
      </div>
    )
  }

  const TotalAmount=()=>{
    return(
      <div style={{background:'rgba(244, 246, 251, 1)', padding:10, margin:10, borderRadius:5}}>
        <div style={{display:'flex',alignItems:'center', margin:10}}>
          <text style={{width:'50%'}}>
            Total Amount
          </text>
          <text style={{width:'50%'}}>
            {shippingCharge ? (Number(totalSellingAmount)+Number(shippingCharge)).toFixed(2) : 'Please select address'}
          </text>
        </div>
      </div>
    )
  }

  const AgreeToTermCondition=()=>{
    return(
      <div style={{background:'rgba(244, 246, 251, 1)', padding:10, margin:10, borderRadius:5}}>
        <lable style={{cursor:'default'}}>
          <input
            required
            type="checkbox"
            style={{margin:15}}
            checked={isAgree}
            onChange={()=>setIsAgree(!isAgree)}
            />
            <a href="https://www.cropway.com/terms-and-conditions/">Agree to terms and conditions</a>
        </lable>
      </div>
    )
  }

  return (
    <Form onSubmit={submit}>
      <div className="address" style={{display:'flex'}}>
        <div style={{width:'100%'}}>
            <h1 style={{ fontWeight: "bold", color: "black" }}>
              {t("Shipping-Order-Summary")}
            </h1>
            <CheckoutSteps step1 />
            <div style={{width:'100%', display:'flex'}}>
              <div style={{width:'60%'}}>
                {
                addNewShippingAddress || userShippingAddress?.length===0 ?
                <AddressForm
                  type={'shipping'}
                  newAddress={newShippingAddress}
                  setNewAddress={setNewShippingAddress}
                  checkPincode={checkPincode}
                  SaveAddress={SaveAddress}
                  cancelNewAddress={cancelNewAddress}
                /> :
                <ShippingAddressCard/>
                }

                <lable style={{margin:10}}>
                  <input
                    type="checkbox"
                    style={{ margin: 10 }}
                    onChange={() =>(
                      setSameAsShipping(
                        prevState =>{const nextState = !prevState;
                        if(nextState){
                          setSelectedBillingAddress(selectedShippingAddress)
                        }
                        else{
                          setSelectedBillingAddress()
                        }
                        return nextState;
                      })
                    )}
                    checked={sameAsShipping}
                  />
                    USE SAME ADDRESS FOR BILLING
                </lable>
                {
                !sameAsShipping && (addNewBillingAddress ?
                  <AddressForm
                    type={'billing'}
                    newAddress={newBillingAddress}
                    setNewAddress={setNewBillingAddress}
                    SaveAddress={SaveAddress}
                    cancelNewAddress={cancelNewAddress}
                  /> :
                  <BillingAddressCard type={'billing'}/>
                )}
              </div>
              <div className="address-amount-detail" style={{width:'40%'}}>
                  <CartDetail/>
                  <Subtotal/>
                  <ShippingCharge shippingCharge={shippingCharge}/>
                  <hr/>
                  <TotalAmount/>
                  <AgreeToTermCondition/>
              </div>
            </div>
        </div>
      </div>
      <div style={{display:'flex', justifyContent:'right'}}>
      <Button
        disabled={cart?.length===0 || !shippingCharge}
        type="submit"
        style={{ ...MediumButtonStyle, cursor:cart?.length===0 || !shippingCharge ? 'not-allowed' : 'pointer'}}
        className="m-4"
      >
        {t("Proceed To Checkout")}
      </Button>
      </div>
    </Form>
  );
}

export default ShippingScreen;
