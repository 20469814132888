import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";
import "react-dropdown-now/style.css";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Loader";
import { ML_MODEL_URL } from "../../../constants/api";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import UploadImage from "../../../assets/cloud-upload.svg";
import { MediumUploadButtonStyle } from "../../../components/ButtonStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { listDiseaseDetectionHistory } from "../../../actions/MlAction";
import { useNavigate } from "react-router-dom";

function DiseasePredict() {
  const theme = useTheme();
  const navigate = useNavigate()
  const xsmall = useMediaQuery(theme.breakpoints.down("xs"));
  const medium = useMediaQuery(theme.breakpoints.up("md"));
  const { t, i18n } = useTranslation();
  const [uploadimage, setUploadimage] = useState("");
  const [preview, setPreview] = useState("");
  const [loading, setLoading] = useState("");

  const setImg = (evt) => {
    setUploadimage(evt.target.files[0]);
    setPreview(URL.createObjectURL(evt.target.files[0]));
  };

  const SavedResult = (
    disease_class_name,
    crop_name,
    disease_name,
    accuracy,
    file_name,
    status
  ) => {
    const uploadData = new FormData();
    uploadData.append("disease_class_name", disease_class_name);
    uploadData.append("ln", i18n.language);
    try {
      setLoading(true);
      fetch(`${ML_MODEL_URL}disease-predict-data`, {
        method: "POST",
        body: uploadData,
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          data.data["crop"] = crop_name;
          data.data["disease"] = disease_name;
          data.data["percentage"] = accuracy;
          data.data["file_name"] = file_name;
          data.data["status"] = status;
          navigate('/disease-prediction-result', {state: { result: data.data, percentage: data.percentage}});
        });
    } catch (error) {
      console.log(error);
    }
  };

  const SaveData = (user_id) => {
    setLoading(true);
    const uploadData = new FormData();
    uploadData.append("file", uploadimage, uploadimage.name);
    uploadData.append("ln", i18n.language);
    uploadData.append("user_id", user_id);
    try {
      fetch(`${ML_MODEL_URL}disease-predict`, {
        method: "POST",
        body: uploadData,
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          navigate('/disease-prediction-result', {state: { result: data.data, percentage: data.percentage }});
        });
    } catch (error) {
      console.log(error);
    }
  };

  const dispatch = useDispatch();
  const {user} = useSelector((state) => state.auth);

  const DiseaseDetectionHistory = useSelector(
    (state) => state.DiseaseDetectionHistory
  );
  const {
    disease_detection_history,
    disease_detection_history_loading,
  } = DiseaseDetectionHistory;

  useEffect(() => {
    if (user) {
      dispatch(listDiseaseDetectionHistory(user.id, i18n.language));
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader loading={loading}/>
      ) : (
        <div>
          <div
            style={{ textAlign: "center", marginTop: "3%", marginBottom: "1%" }}
          >
            <Typography
              variant="h3"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "1.625rem",
              }}
            >
              {t("diseaseDetection")}
            </Typography>
          </div>

          <div style={{ textAlign: "center" }}>
            <Box
              position="relative"
              justifyContent="center"
              borderRadius="16px"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 1,
                  width: 800,
                  height: 500,
                  minWidth: 100,
                },
              }}
            >
              <Paper style={{ background: "black" }}>
                <div style={{ marginTop: "4%" }}>
                  <Typography
                    variant="h4"
                    style={{ color: "#FFFFFF", fontSize: "1.375rem" }}
                  >
                    {t("uploadImage")}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: "#FFFFFF", fontSize: "0.875rem" }}
                  >
                    {t("jpgAllowed")}
                  </Typography>
                </div>
                <Paper
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    margin: 10,
                    minHeight: 251,
                  }}
                >
                  <img
                    width={preview ? 300 : 81}
                    height={preview ? 300 : 81}
                    src={preview ? preview : UploadImage}
                  />
                  <Typography
                    style={{
                      display: preview ? "none" : true,
                      fontSize: "0.875rem",
                      fontWeight: "bold",
                      marginTop: "0.8rem",
                    }}
                  >
                    {t("dragDropBrowse")}
                  </Typography>
                </Paper>
              </Paper>
            </Box>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "15vh",
              marginTop: "3rem",
            }}
          >
            <Stack
              direction={xsmall ? "column" : "row"}
              spacing={xsmall ? 3 : medium ? 32 : 10}
            >
              <div
                style={{
                  ...MediumUploadButtonStyle,
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <text>{t("upload")}</text>
                <input
                  type="file"
                  onChange={(evt) => setImg(evt)}
                  style={{
                    zIndex: "1",
                    position: "absolute",
                    opacity: 0,
                    top: 0,
                    left: 0,
                    cursor: "pointer",
                  }}
                />
              </div>

              <Button
                style={MediumUploadButtonStyle}
                onClick={() => SaveData(user.id)}
              >
                <span>
                  {" "}
                  {t("Submit")}
                  <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                </span>
              </Button>
            </Stack>
          </div>

          {disease_detection_history_loading ? null :
            <div>
            <h1>{disease_detection_history && disease_detection_history.length > 0 && t("PreviousResult")}</h1>
            <Row xs={2} md={5}>
              {disease_detection_history &&
                disease_detection_history.map((data) => (
                  <Col
                    
                    style={{ paddingBottom: 20, cursor: "pointer" }}
                    onClick={() =>
                      SavedResult(
                        data.prediction,
                        data.crop_name,
                        data.disease_name,
                        data.accuracy,
                        data.file_name,
                        data.status
                      )
                    }
                  >
                    <div className="card ml-img-card">
                      <img
                        className="card-img-top"
                        src={data.diseased_image}
                        alt="Card image cap"
                        maxWidth={100}
                        width="100"
                      />
                      <div className="card-body">
                        <h5 className="card-title">{data.disease_name}</h5>
                        <div>
                          <text>
                            <text style={{ fontWeight: 600 }}>{t("cropname")}:</text>{" "}
                            {` ${data.crop_name}`}
                          </text>
                        </div>
                        <div>
                          <div>
                            <text>
                              <text style={{ fontWeight: 600 }}>{t("accuracy")}:</text>{" "}
                              {` ${data.accuracy}%`}
                            </text>
                          </div>
                          <text style={{ fontWeight: 600, color: "red" }}>
                            {data.status === "True" &&
                            data.predicted_accurately === "No"
                              ? "False Prediction"
                              : ""}
                          </text>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
          }
        </div>
      )}
    </>
  );
}

export default DiseasePredict;
