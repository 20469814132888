import React, { useState, useEffect } from "react";
import { MediumButtonStyle } from "../components/ButtonStyle";
import ReactImageMagnify from "react-image-magnify";
import productService from "../services/product.service";
import cartService from '../services/cart.service';
import mathService from "../services/math.service";
import '../components/Style/productview.css';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { addToWishlist, removeFromWishlist } from "../actions/wishlist.action";
import { getWishlistCartDetails } from "../actions/cart.action";

function ProductScreenWithVariation() {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { user } = useSelector(state=>state.auth)
  const {inCart, inWishlist, quotation } = useSelector(state=>state.wishlistCartDetail)
  const { productId } = location?.state
  const [product, setProduct] = useState()
  const [selectedProduct, setSelectedProduct] = useState()
  const [loading, setLoading] = useState(true)
  const [requestQuotationModalVisible, setRequestQuotationModalVisible] = useState(false)
  const [quotationQuantity, setQuotationQuantity] = useState()
  const [quotationAmount, setQuotationAmount] = useState()
  // let imageURL = 'https://ik.imagekit.io/oet53wxls7nq/'+(product?.variation[0]?.media[0]?.image?.image)?.replace(/\/media\/(marketplace\/)?/g, "")
  let imageURL = (product?.variation[0]?.media[0]?.image?.image)?.replace(/\/media\/(marketplace\/)?/g, "")
  const [selectedImage, setSelectedImage] = useState()
  let {offPercent} = mathService.discountRate(product?.variation[0]?.stock?.mrp, product?.variation[0]?.stock?.selling_price)

  const cartWishlistDetail=(variation_id)=>{
    dispatch(getWishlistCartDetails(variation_id))
  }

  const goTocart=()=>{
      navigate('/cart')
  }

  const addTocart=()=>{
    setLoading(true)
    let cartData = {variation_id:null, quantity:null}
    cartData.variation_id = selectedProduct.id
    cartData.quantity = 1

    cartService.addToCartHandler(cartData)
                  .then((res)=>{
                    cartWishlistDetail(selectedProduct.id)
                    setLoading(false)
                    // setRefreshing(false)
                  })
                  .catch((error)=>{
                    console.log('addToCartHandler-error :: ',error)
                    setLoading(false)
                    // setRefreshing(false)
                    // console.log(error)
                    // alert(error)
                  })
  }

  const wishlistHandler=(id)=>{
    inWishlist ?
    dispatch(removeFromWishlist(id)) :
    dispatch(addToWishlist(id))
  }

  const requestQuotationHandler=()=>{
    console.log('requestQuotationHandler')
    setRequestQuotationModalVisible(true)
  }

  const submitQuotation=()=>{
    console.log('submitQuotation')
    let data={
      variation_id : selectedProduct?.id,
      quantity : quotationQuantity,
      amount : quotationAmount,
    }
    cartService.quationHandler(data)
               .then((res)=>{
                setRequestQuotationModalVisible(false)
                alert('quote send')
               })
               .catch((error)=>{
                console.log(error)
                setLoading(false)
              })
  }

  useEffect(()=>{
    !product && productId && productService.getProductDetail(productId)
                  .then((res)=>{
                    setProduct(res?.data)
                    setSelectedProduct(res?.data?.variation[0])
                    return res?.data?.variation[0]
                  })
                  .then((variation)=>{
                    cartWishlistDetail(variation.id)
                    setLoading(false)
                  })
                  .catch((error)=>{
                    console.log(error)
                    setLoading(false)
                  })
   },[product, productId])

  const ImageModule=()=>{
    return(
      <div style={{width:'40vh'}}>
        <div className="product-view-image-main">
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: 'product',
                isFluidWidth: true,
                src:selectedImage,
              },
              largeImage: {
                width: 1400,
                height: 1400,
                src:selectedImage,
              },
              enlargedImageContainerStyle: {
                background: "#fff",
                zIndex: 9,
              },
              lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
            }}
          />
        </div>
        <div className="product-view-image-child">
          {
            selectedProduct?.media?.map((media, key)=>{
              // let imageURL = 'https://ik.imagekit.io/oet53wxls7nq/'+(media.image.image)?.replace(/\/media\/(marketplace\/)?/g, "")
              let imageURL = (media.image.image)?.replace(/\/media\/(marketplace\/)?/g, "")
              !selectedImage && setSelectedImage(imageURL)
              return(
                <div key={key} style={{display:'flex', justifyContent:'space-evenly'}} onClick={()=>setSelectedImage(imageURL)}>
                  <img src={imageURL} width={80} style={{margin:5}} alt=""/>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  const PriceSection=()=>{
    return(
          <div className="price" style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
            <div>
              <strike style={{padding:'0px 4px',fontSize:12}}>MRP : ₹ {selectedProduct?.stock?.mrp}</strike>
              <strong style={{padding:'0px 4px',fontSize:14, color:'green'}}>{offPercent}% off</strong>
            </div>
            <strong style={{padding:'0px 4px',fontSize:18}}>₹ {selectedProduct?.stock?.selling_price}</strong>
          </div>
    )
  }

  const Variation=()=>{
    return(
      <div style={{padding:10}}>
      <h6>{product?.variation?.length>1 ? product?.variation[0]?.variation_name?.name : 'One Size'}</h6>
      <div style={{display:'flex', flexDirection:'row'}}>
      {
        product?.variation?.map((res, key)=>(
          <div
            key={key}
            style={{
                width:50,
                height:50,
                borderRadius:5,
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                margin:5,
                cursor:'default',
                backgroundColor: res.id === selectedProduct?.id ? '#fff' :'gray',
                color:res.id === selectedProduct?.id ? '#000' :'#fff',
                border:res.id === selectedProduct?.id ? '1px solid #000' : null,
                }}
            onClick={()=>(setSelectedProduct(res), cartWishlistDetail(res?.id))}
          >
            {product?.variation?.length>1 && <div>{res?.variation_name?.value}</div>}
            <div style={{fontSize:10}}>{res?.stock?.product_weight + res?.stock?.unit}</div>
          </div>
        ))
        }
      </div>
    </div> 
    )
  }

  const Action=({title='button', action})=>{
    return(
        <button
           style={{...MediumButtonStyle, border:'none', fontSize:20, margin:10, padding:5}}
           onClick={()=> user ? action() : (alert('Please sign in to continue.'), navigate('/login'))}
        >
          {title}
        </button>
    )
  }

  const Description=({numberOfLines = undefined})=>{
    const splitByPeriod = product?.description?.split(/\n\n|:/) //split by '\n\n' or ':'
    const descriptionText = splitByPeriod?.flatMap(point => point.split(/\.\n/)).slice(0, numberOfLines).map((point, index) => { //split by .\n
      return (
        <li style={{listStyleType:'circle'}} key={index}>{point.trim()}</li>
      );
    });

    return(
      <div style={{padding:10, overflow:'hidden', textOverflow:'ellipsis', height:'100%',}}>
        <text style={{fontSize:20}}>About Product:</text>
        <ul>{descriptionText}</ul>
      </div>
    )
  }

  const VerticleDivider=()=>{
    return(
      <div style={{borderLeft:'1px dotted gray', alignSelf:'stretch', width:0}}/>
    )
  }

  const OutOfStock=()=>{
    return(
      <div style={{background:'#800000', color:'white', margin:10, padding:10, borderRadius:10, width:'100%', textAlign:'center'}}>
            Out of stock
      </div>
    )
  }

  const CheckPincode=()=>{
    return(
      <div style={{height:'50%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', padding:10}}>
        <div style={{border:'1px solid gray', borderRadius:5}}>
          <div style={{display:'flex', flexDirection:'column', padding:15}}>
          <text style={{fontSize:20}}>Check address : </text>
          <input style={{fontSize:20}} type="number" placeholder="Enter pincode"></input>
          </div>
          <Action title={'Check'} action={'check'}/>
        </div>
      </div>
    )
  }

  const notReady=()=>{
    alert('This feature is not available now.')
  }
  
  const RequestQuotation = ({ handleConfirm }) => {
    return (
      <div style={{ display: requestQuotationModalVisible ? 'block' : 'none', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)' }}>
          <h2>Quote Product</h2>
          <div style={{display:'flex', flexDirection:'column', justifyContent:'space-around'}}>
            <lable style={{margin:10, display:'flex', justifyContent:'space-between'}}>
              Quantity :
              <input type="number" placeholder="Enter your required quantity" min='10' value={quotationQuantity}onChange={(e)=> setQuotationQuantity(e.target.value)} />
            </lable>
            <lable style={{margin:10, display:'flex', justifyContent:'space-between'}}>
              Quatation price/quantity : 
              <input type="number" placeholder="Enter your quation price" min='0' value={quotationAmount}onChange={(e)=> setQuotationAmount(e.target.value)} />
            </lable>
          </div>
          <div style={{ textAlign: 'center' }}>
            <button onClick={()=>submitQuotation()} style={{ borderRadius:5 ,margin: 10, padding: 10, cursor: 'pointer' }}>OK</button>
            <button onClick={() => setRequestQuotationModalVisible()} style={{ borderRadius:5 ,margin: 10, padding: 10, cursor: 'pointer' }}>Cancel</button>
          </div>
        </div>
      </div>
    );
  };

  const QuoteDetails=()=>{
    return(
      <div style={{display:'flex', flexDirection:'column'}}>
        <h5>Your Quote:</h5>
        <text>Quantity: {quotation?.quotation?.quantity}</text>
        <text>Amount: {quotation?.quotation?.amount}</text>
      </div>
    )
  }

  return (
    <>
    <Loader loading={loading}/>
    <RequestQuotation/>
    <div className="product-view-desktop">
      <div style={{display:'flex', flexDirection:'row', padding:10, justifyContent:'space-evenly', maxHeight:'80vh', minHeight:'80vh'}}>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
          <ImageModule/>
        </div>
        <VerticleDivider/>

        <div style={{width:'30%'}}>
          <div style={{height:'fit-content', padding:10, }}>
            <div style={{display:'flex', width:'100%', justifyContent:'space-between', alignItems:'center'}}>
              <h4 style={{ color: "black" }}>{product?.name}</h4>
              <i className="fas fa-heart" style={{color:inWishlist?'red':'gray', fontSize:25}} onClick={()=>wishlistHandler(selectedProduct?.id)}/>
            </div>
            <h6>{product?.brand.name}</h6>
            {product?.product_type==1 ? <PriceSection/> : 'Please send your quotation'}
            <Variation/>
          </div>
          <hr/>
          <div style={{height:'36vh'}}>
            <Description numberOfLines={4}/>
          </div>
        </div>

        <VerticleDivider/>

        <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around', height:'45vh'}}>
          {
            selectedProduct?.stock?.total_stock===0 ?
            <OutOfStock/>
            :
            <>
              {inCart ? <Action title={'Go to Cart'} action={goTocart}/> : <Action title={'Add to Cart'} action={addTocart}/>}
              <Action title={'Add Review'} action={notReady}/>
              <Action title={'Ask Question'} action={notReady}/>
            </>
          }
          {quotation?.quotation && user ? <QuoteDetails/> : <Action title={'Request Quotation'} action={requestQuotationHandler}/> }
          {/* check pincode */}
          {/* <CheckPincode/> */}
        </div>
      </div>
      <hr/>
      <div style={{display:'flex', flexDirection:'row', padding:10, justifyContent:'space-evenly', maxHeight:'80vh', minHeight:'80vh'}}>
        <Description/>
      </div>
    </div>

    {/* -----------------------------------------mobile view--------------------------------------------- */}
    
    <div className="product-view-mobile">
      <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
        <ImageModule/>

        <div>
          <div style={{padding:10}}>
            <h4 style={{ color: "black" }}>{product?.name}</h4>
            <h6>{product?.brand.name}</h6>
            <PriceSection/>
          </div> 
          <Variation/>
          <hr/>

          <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around'}}>
            {
              selectedProduct?.stock?.total_stock===0 ?
              <OutOfStock/>
              :
              <>
                {inCart ? <Action title={'Go to Cart'} action={goTocart}/> : <Action title={'Add to Cart'} action={addTocart}/>}
                <Action title={'Add Review'} action={notReady}/>
                <Action title={'Ask Question'} action={notReady}/>
              </>
            }
            {quotation?.quotation  && user ? <QuoteDetails/> : <Action title={'Request Quotation'} action={requestQuotationHandler}/> }
            {/* check pincode */}
            {/* <CheckPincode/> */}
          </div>
        </div>
      </div>
      <hr/>
      <div style={{display:'flex', flexDirection:'row', padding:10, justifyContent:'space-evenly'}}>
        <Description/>
      </div>
    </div>
    </>
  );
}

export default ProductScreenWithVariation;
