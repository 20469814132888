import React, { useState } from "react";
import { MediumButtonStyle } from "./ButtonStyle";
import mathService from "../services/math.service";
import '../components/Style/cart.css';
import cartImage from '../assets/slider4.jpg'
import secure from '../assets/support/security.png';
import helpcenter from '../assets/support/helpcenter.png';
import faq from '../assets/support/faq.png';

const uap_images=[
  {
    icon:secure,
    title:'Secure Payment'
  },
  {
    icon:helpcenter,
    title:'Help Center'
  },
  {
    icon:faq,
    title:'FAQ'
  },
]


export const CartDesktopSection = ({cart, proceedToCheckout, updateCart, removeFromCart}) => {
  const [edit, setEdit] = useState()

  const mrp = cart && mathService.totalCartAmount(cart).totalMRP
  const totalSellingAmount = cart && mathService.totalCartAmount(cart).totalSellingAmount
  const discountAmount = cart && mathService.discountRate(
                                 mathService.totalCartAmount(cart).totalMRP,
                                 mathService.totalCartAmount(cart).totalSellingAmount
                                 ).offPrice

  // -------components----------
  const EditableCell = ({ quantity, cartId }) => {
    const [value, setValue] = useState(quantity);
    const [submitText, setSubmitText] = useState('Save')
    
    const handleInputChange = (event) => {
      if(event.target.value <= 5 && event.target.value>0)
      {
        setValue(event.target.value);
      }
      else if(event.target.value<0){
        setSubmitText('lower limit : 0')
      }
      else if(event.target.value>5){
        setSubmitText('upper limit : 5')
      }
      else{
        setSubmitText('Save')
      }
    };
  
    return (
      <td>
        <input
          type="number"
          value={value}
          onChange={handleInputChange}
          style={{borderRadius:5, border:'1px solid gray'}}
        />
        <text
          onClick={()=>(updateCart(cartId, value), setEdit())}
        >
          {submitText}
        </text>
      </td>
    );
  }

  const CartTable=()=>{
    return(
      <div
        style={{
          border:'1px solid gray',
          backgroundColor: '#fff',
          borderRadius: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 10,
          margin: 10,
          }}
      >
      <table
      style={{
        borderCollapse: 'separate !important',
        borderSpacing: '0.8vmin 0.5vmin',
        width: '100%',
      }}
        >
        <thead>
          <tr>
            <td colSpan={7} style={{fontSize:20, padding:10}} >Shopping Bag</td>
          </tr>
        </thead>
        <tbody>
        <tr 
         style={{
          backgroundColor: '#fff',
          textAlign: 'center',
          fontSize: '1.5vmin',
          borderRadius: '5px',
        }}
        >
          <th style={{minWidth:'20%', borderRadius: 5}}>{'#'}</th>
          <th style={{minWidth:'20%', borderRadius: 5}}>{'Product'}</th>
          <th style={{minWidth:'20%', borderRadius: 5}}>{'Name'}</th>
          <th style={{minWidth:'20%', borderRadius: 5}}>{'Brand'}</th>
          <th style={{minWidth:'20%', borderRadius: 5}}>{'Quantity'}</th>
          <th style={{minWidth:'20%', borderRadius: 5}}>{'Size'}</th>
          <th style={{minWidth:'20%', borderRadius: 5}}>{'Price'}</th>
          <th style={{minWidth:'20%', borderRadius: 5}}>{'Delete'}</th>
        </tr>
        {cart?.length==0 &&
          <tr 
            style={{
              backgroundColor: '#fff',
              textAlign: 'center',
              fontSize: '1.5vmin',
              borderRadius: '5px',
            }}
          >
            <th colSpan={8} style={{minWidth:'20%', borderRadius: 5, height:50, borderRadius:5, background:'rgba(244, 246, 251, 1)'}}>
              {'Shopping bag is empty'}
            </th>
          </tr>
        }
        {
          cart?.map((item, key)=>{
            // let imageURL = 'https://ik.imagekit.io/oet53wxls7nq/'+(item?.product_variation?.media?.[0]?.image?.image)?.replace(/\/media\/(marketplace\/)?/g, "")
            let imageURL = (item?.product_variation?.media?.[0]?.image?.image)?.replace(/\/media\/(marketplace\/)?/g, "")
            return(
              <tr key={key} style={{height:40}}>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                <input type="checkbox"/>
              </td>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                <img src={imageURL} style={{aspectRatio:1, objectFit:'cover', height:80, width:80, margin:10, borderRadius:5}} alt="product"/>
              </td>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                {item?.product_variation?.product?.name}
              </td>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                {item?.product_variation?.product?.brand?.name}
              </td>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                {
                  edit==item?.id ?
                  <EditableCell quantity={item?.quantity} cartId={item?.id}/> :
                  <text style={{cursor:'pointer'}} onClick={()=>setEdit(item.id)}>{item?.quantity} edit</text>
                }
              </td>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                {item?.product_variation?.variation_name?.value}
              </td>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                {item?.product_variation?.stock?.selling_price}
              </td>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                <text style={{cursor:'pointer'}} onClick={()=>removeFromCart(item.id)}>{'Delete'}</text>
              </td>
            </tr>
            )
          })
        }
        </tbody>
      </table>
      </div>
    )
  }

  const Action=({title='button', action, disable})=>{
    return(
        <button
           disabled={disable}
           style={{...MediumButtonStyle, border:'none', fontSize:20, margin:10, padding:5, cursor:disable?'not-allowed':'pointer'}}
           onClick={()=>action()}
        >
          {title}
        </button>
    )
  }

  const OrderSummary=()=>{
    const SummaryText=({title, value, style})=>{
      return(
        <div style={{display:'flex', justifyContent:'space-between', width:'70%', margin:10}}>
          <text style={{fontSize:20, ...style}}>{title}</text>
          <text style={{fontSize:20, ...style}}>{value}</text>
        </div>
      )
    }
    return(
      <div className="order-summary">
        <div>
          <h4>Order Summary</h4>
          <SummaryText title={'Item(s)'} value={cart?.length}/>
          <SummaryText title={'MRP ₹'} value={mrp}/>
          <SummaryText title={'Discount ₹'} value={discountAmount} style={{color:'green'}}/>
          <hr/>
          <SummaryText title={'Total Amount ₹'} value={totalSellingAmount}/>
        </div>
        <Action title={'Proceed To Checkout'} action={proceedToCheckout} disable={cart?.length==0}/>
      </div>
    )
  }

  const UAP=()=>{
    return(
      <div style={{display:'flex', justifyContent:'space-evenly',alignItems:'center', margin:10, padding:10, background:'rgba(244, 246, 251, 1)', borderRadius:10}}>
            <div style={{display:'flex', justifyContent:'space-around', width:'100%'}}>
              {
                uap_images?.map((data, key)=>(
                  <div key={key} style={{
                    display:'flex',
                    flexDirection:'column',
                    background:'#fff',
                    padding:30,
                    justifyContent:'center',
                    alignItems:'center',
                    borderRadius:10
                    }}>
                    <img src={data?.icon} style={{width:120}}/>
                    <text style={{fontSize:18, bottom:0}}>{data?.title}</text>
                  </div>
                ))
              }
          </div>
      </div>
    )
  }

  return (
     <div className="cart">
      <div>
        <img className="cart-image" src={cartImage}/> 
        <CartTable/>
        <UAP/>
      </div>
        <OrderSummary/>
     </div>
    );
  };
  