import React from 'react';
import { SmallButtonStyle } from '../ButtonStyle';
import FarmerRafiki from '../../assets/FarmerRafiki.png';
import { BackgroundGradientStyle } from '../BackgoundGradient';
import Message from '../message';
import { Form, Button } from 'react-bootstrap';
import Loader from '../Loader';

export const LoginDesktopSection = ({
  loading,
  submitHandler,
  username,
  setUsername,
  password,
  setPassword,
  error,
  t,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Loader loading={loading}/>
      <div style={BackgroundGradientStyle}>
        {/* confirm template */}
        <div style={{ fontSize: 12 }}>
          {error && <Message variant='danger'>{error}</Message>}
        </div>
          <div style={{ color: 'black' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  width: '30vw',
                  color: 'black',
                  backgroundColor: 'white',
                  margin: '2%',
                  padding: '2%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '30vw',
                  height: 'auto',
                }}
              >
                <img
                  alt='Farmer Rafiki'
                  src={FarmerRafiki}
                  style={{ height: '25vw', width: '20vw', borderRadius: 0 }}
                />
              </div>

              <div
                style={{
                  height: 'fit-content',
                  width: '30vw',
                  backgroundColor: 'white',
                  margin: '2%',
                  padding: '2%',
                  width: '30vw',
                  height: 'auto',
                }}
              >
                <strong style={{ color: 'black', fontSize: '2vw' }}>
                  {t('Welcome-Back')}
                </strong>
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId='username'>
                    <Form.Label style={{ marginTop: '5%', fontSize: '1.5vw' }}>
                      {t('Login-Continue')}
                    </Form.Label>
                    <Form.Control
                      style={{ borderRadius: 0, fontSize: '1vw' }}
                      type='username'
                      placeholder={t('Enter-Username')}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='password'>
                    <Form.Control
                      style={{ borderRadius: 0, fontSize: '1vw' }}
                      type='password'
                      placeholder={t('Enter-Pass')}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <div style={{ textAlign: 'right', fontSize: '1vw' }}>
                    <a href='/reset_password'>{t('Forget-Pass')}</a>
                  </div>

                  <div
                    style={{
                      marginTop: '5%',
                      marginBottom: '5%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Button type='submit' style={SmallButtonStyle}>
                      {t('Sign-In')}{' '}
                      <i style={{ marginLeft: 5 }} className='fas fa-angle-right' />
                    </Button>
                  </div>

                  <div>
                    <a style={{ fontSize: '1vw' }} href='/Register'>
                      {t('Create-Acc')}
                    </a>
                  </div>
                </Form>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};
