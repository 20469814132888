import React, { useState } from "react";
import { Button, Form} from "react-bootstrap";
import Typography from "@material-ui/core/Typography";
import { Dropdown } from "react-dropdown-now";
import "react-dropdown-now/style.css";
import {
  BigButtonStyle,
} from "../../components/ButtonStyle";
import Ferilizer from "../../assets/fertilizer.png";
import vegetables from "../../assets/vegetables.svg";
import { Box, Grid } from "@mui/material";
import { crop_arr } from "../../constants/utils/NutrientPre";

export const NutrientDesktop =({
    cropname,
    nitrogen,
    phosphorous,
    sulfur,
    pottasium,
    zinc,
    magnesium,
    calcium,
    boron,
    iron,
    copper,
    manganese,
    ph_value,
    SaveData,
    setCropname,
    setNitrogen,
    setPhosphorous,
    setPottasium,
    setSulfur,
    setZinc,
    setMagnesium,
    setCalcium,
    setBoron,
    setIron,
    setCopper,
    setManganese,
    setPhValue,
    t
})=>{
   const [nutrentValue,setNutrentValue]=useState({
    "cropname":cropname,
    "nitrogen":nitrogen,
    "phosphorous":phosphorous,
    "sulfur":sulfur,
    "pottasium":pottasium,
    "zinc":zinc,
    "magnesium":magnesium,
    "calcium":calcium,
    "boron":boron,
    "iron":iron,
    "copper":copper,
    "manganese":manganese,
    "ph_value":ph_value,
   })
 
    const FormGroupStyle = {
        borderRadius: 0,
        marginTop: 20,
        border: 0,
        borderBottom: "1px solid #5DA7CA",
        marginBottom: 28,
      }
      const FormLabelStyle = { marginBottom: 3 }
      const FormLabelDivStyle = { display: "flex", paddingRight: 3 }
      const BoxStyle ={
        background: "0px 2px 5px rgba(0, 0, 0, 0.25)",
        backgroundColor: "#000000",
        width: "1.3rem",
        height: 21,
        borderRadius: 4,
        textAlign: "center",
        marginRight: 3,
      }
      const SpanStyle = {
        color: "#FFFFFF",
        fontWeight: 400,
        width: "0.8rem",
        height: 21,
      }
      const FormControlStyle = {
        width: "100%",
        borderRadius: 0,
        fontSize: 15,
        boxShadow: "none",
        paddingLeft: 0,
        border: 0,
      }
      const HeadImgStyle= {backgroundImage: `url(${Ferilizer})`, color: 'White', fontWeight: 700, fontSize: '3rem', height: '486px', width:'100%',  backgroundRepeat: "no-repeat",  backgroundPosition: 'center', backgroundSize: 'cover', display: 'flex', justifyContent: 'center', alignItems: 'center'}
    
      const GridTopStyle={paddingTop: '1%'}

    return(<div>
        <div style= {HeadImgStyle}> 
         {t( "NutrientPredictor")}
         </div>

    <text style={{display: 'flex', justifyContent: 'center', paddingBottom: '1%', paddingTop: '2%', fontSize: '2rem', color: 'black', fontWeight: '5px'}} > {t("InputNutrientValues")}</text>


<Grid container justifyContent = 'center' style={GridTopStyle} >
 <Grid item xs={3}>
 <Form.Group controlId="cropname">
                  <Form.Label>
                    <img height={21} src={vegetables} alt="vegetable_icon" />
                    {t("SelectCrop")}
                  </Form.Label>
                  <Dropdown
                    placeholder={t("Selectanoption")}
                    options={crop_arr}
                    onSelect={(value) => {
                      setCropname(value.value);
                    }} 
                  />
                </Form.Group>
 </Grid>
    </Grid>




    <Grid container justifyContent='center' style={GridTopStyle} >
      <Grid item xs={0.5}/>
      <Grid item xs={2}>
                    <Form.Group
                      controlId="Nitrogen"
                      style={FormGroupStyle}
                    >
                      <Form.Label style={FormLabelStyle}>
                        <div style={FormLabelDivStyle}>
                          <Box
                            style={BoxStyle}
                          >
                            <span
                              style={SpanStyle}
                            >
                              N
                            </span>
                          </Box>{" "}
                          <Typography> {t("Nitrogen")} {t("kgHa")}</Typography>
                        </div>
                      </Form.Label>
                      <Form.Control
                        style={FormControlStyle}
                        type="numeric"
                        placeholder={t("EnterValue")}
                        value={nitrogen}
                        onChange={(e) => setNitrogen(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    {/* {!nitrogen?<div style={{ color: 'red' }}>Value cannot be empty</div>:null} */}
                    {!nitrogen.trim() ? <div style={{ color: 'red' }}>Value cannot be empty</div> : null}
      </Grid>
      
      <Grid item xs={0.5}/>

      <Grid item xs={2}>
                    <Form.Group
                      controlId="Phosphorous"
                      style={FormGroupStyle}
                    >
                      <Form.Label style={FormLabelStyle}>
                        <div style={FormLabelDivStyle}>
                          <Box
                            style={BoxStyle}
                          >
                            <span
                              style={SpanStyle}
                            >
                              P
                            </span>
                          </Box>{" "}
                          <Typography> {t("Phosphorous")} {t("kgHa")}</Typography>
                        </div>
                      </Form.Label>
                      <Form.Control
                        style={FormControlStyle}
                        type="numeric"
                        placeholder={t("EnterValue")}
                        value={phosphorous}
                        onChange={(e) => setPhosphorous(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
      </Grid>

      <Grid item xs={0.5}/>
  
      <Grid item xs={2}>
                    <Form.Group
                      controlId="Pottasium"
                      style={FormGroupStyle}
                    >
                      <Form.Label style={FormLabelStyle}>
                        <div style={FormLabelDivStyle}>
                          <Box
                            style={BoxStyle}
                          >
                            <span
                              style={SpanStyle}
                            >
                              K
                            </span>
                          </Box>{" "}
                          <Typography> {t("Pottasium")} {t("kgHa")}</Typography>
                        </div>
                      </Form.Label>
                      <Form.Control
                        style={FormControlStyle}
                        type="numeric"
                        placeholder={t("EnterValue")}
                        value={pottasium}
                        onChange={(e) => setPottasium(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
      </Grid>
      
      <Grid item xs={0.5}/>
 
      <Grid item xs={2}>
                    <Form.Group
                      controlId="Sulfur"
                      style={FormGroupStyle}
                    >
                      <Form.Label style={FormLabelStyle}>
                        <div style={FormLabelDivStyle}>
                          <Box
                            style={BoxStyle}
                          >
                            <span
                              style={SpanStyle}
                            >
                              S
                            </span>
                          </Box>{" "}
                          <Typography> {t("Sulfur")} {t("ppm")}</Typography>
                        </div>
                      </Form.Label>
                      <Form.Control
                        style={FormControlStyle}
                        type="numeric"
                        placeholder={t("EnterValue")}
                        value={sulfur}
                        onChange={(e) => setSulfur(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
      </Grid>
      <Grid item xs={0.5}/>
    </Grid>




    <Grid container justifyContent='center' style={GridTopStyle} >
      <Grid item xs={0.5}/>
      <Grid item xs={2}>
                    <Form.Group
                      controlId="Zinc"
                      style={FormGroupStyle}
                    >
                      <Form.Label style={FormLabelStyle}>
                        <div style={FormLabelDivStyle}>
                          <Box
                            style={BoxStyle}
                          >
                            <span
                              style={SpanStyle}
                            >
                              Zn
                            </span>
                          </Box>{" "}
                          <Typography> {t("Zinc")} {t("ppm")}</Typography>
                        </div>
                      </Form.Label>
                      <Form.Control
                        style={FormControlStyle}
                        type="numeric"
                        placeholder={t("EnterValue")}
                        value={zinc}
                        onChange={(e) => setZinc(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
      </Grid>
      
      <Grid item xs={0.5}/>

      <Grid item xs={2}>
                    <Form.Group
                      controlId="Magnesium"
                      style={FormGroupStyle}
                    >
                      <Form.Label style={FormLabelStyle}>
                        <div style={FormLabelDivStyle}>
                          <Box
                            style={BoxStyle}
                          >
                            <span
                              style={SpanStyle}
                            >
                              Mg
                            </span>
                          </Box>{" "}
                          <Typography> {t("Magnesium")} {t("ppm")}</Typography>
                        </div>
                      </Form.Label>
                      <Form.Control
                        style={FormControlStyle}
                        type="numeric"
                        placeholder={t("EnterValue")}
                        value={magnesium}
                        onChange={(e) => setMagnesium(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
      </Grid>

      <Grid item xs={0.5}/>
  
      <Grid item xs={2}>
                    <Form.Group
                      controlId="Calcium"
                      style={FormGroupStyle}
                    >
                      <Form.Label style={FormLabelStyle}>
                        <div style={FormLabelDivStyle}>
                          <Box
                            style={BoxStyle}
                          >
                            <span
                              style={SpanStyle}
                            >
                              Ca
                            </span>
                          </Box>{" "}
                          <Typography> {t("Calcium")} {t("ppm")}</Typography>
                        </div>
                      </Form.Label>
                      <Form.Control
                        style={FormControlStyle}
                        type="numeric"
                        placeholder={t("EnterValue")}
                        value={calcium}
                        onChange={(e) => setCalcium(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
      </Grid>
      
      <Grid item xs={0.5}/>
 
      <Grid item xs={2}>
                    <Form.Group
                      controlId="Boron"
                      style={FormGroupStyle}
                    >
                      <Form.Label style={FormLabelStyle}>
                        <div style={FormLabelDivStyle}>
                          <Box
                            style={BoxStyle}
                          >
                            <span
                              style={SpanStyle}
                            >
                              B
                            </span>
                          </Box>{" "}
                          <Typography> {t("Boron")} {t("ppm")}</Typography>
                        </div>
                      </Form.Label>
                      <Form.Control
                        style={FormControlStyle}
                        type="numeric"
                        placeholder={t("EnterValue")}
                        value={boron}
                        onChange={(e) => setBoron(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
      </Grid>
      
      <Grid item xs={0.5}/>
    </Grid>






    
    <Grid container justifyContent='center' style={GridTopStyle} >
      <Grid item xs={0.5}/>

      <Grid item xs={2}>
                    <Form.Group
                      controlId="Iron"
                      style={FormGroupStyle}
                    >
                      <Form.Label style={FormLabelStyle}>
                        <div style={FormLabelDivStyle}>
                          <Box
                            style={BoxStyle}
                          >
                            <span
                              style={SpanStyle}
                            >
                              Fe
                            </span>
                          </Box>{" "}
                          <Typography> {t("Iron")} {t("ppm")}</Typography>
                        </div>
                      </Form.Label>
                      <Form.Control
                        style={FormControlStyle}
                        type="numeric"
                        placeholder={t("EnterValue")}
                        value={iron}
                        onChange={(e) => setIron(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
      </Grid>

      <Grid item xs={0.5}/>
  
      <Grid item xs={2}>
                    <Form.Group
                      controlId="Copper"
                      style={FormGroupStyle}
                    >
                      <Form.Label style={FormLabelStyle}>
                        <div style={FormLabelDivStyle}>
                          <Box
                            style={BoxStyle}
                          >
                            <span
                              style={SpanStyle}
                            >
                              Co
                            </span>
                          </Box>{" "}
                          <Typography> {t("Copper")} {t("ppm")}</Typography>
                        </div>
                      </Form.Label>
                      <Form.Control
                        style={FormControlStyle}
                        type="numeric"
                        placeholder={t("EnterValue")}
                        value={copper}
                        onChange={(e) => setCopper(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
      </Grid>
      
      <Grid item xs={0.5}/>
 
      <Grid item xs={2}>
                    <Form.Group
                      controlId="Manganese"
                      style={FormGroupStyle}
                    >
                      <Form.Label style={FormLabelStyle}>
                        <div style={FormLabelDivStyle}>
                          <Box
                            style={BoxStyle}
                          >
                            <span
                              style={SpanStyle}
                            >
                              Mn
                            </span>
                          </Box>{" "}
                          <Typography> {t("Manganese")} {t("ppm")}</Typography>
                        </div>
                      </Form.Label>
                      <Form.Control
                        style={FormControlStyle}
                        type="numeric"
                        placeholder={t("EnterValue")}
                        value={manganese}
                        onChange={(e) => setManganese(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
      </Grid>
      <Grid item xs={0.5}/>

      <Grid item xs={2}>
               <Form.Group
                 controlId="Potential of Hydrogen"
                 style={FormGroupStyle}
               >
                 <Form.Label style={FormLabelStyle}>
                   <div style={FormLabelDivStyle}>
                     <Box
                       style={BoxStyle}
                     >
                       <span
                         style={SpanStyle}
                       >
                         pH
                       </span>
                     </Box>{" "}
                     <Typography> {t("PotentialofHydrogen")}</Typography>
                   </div>
                 </Form.Label>
                 <Form.Control
                   style={FormControlStyle}
                   type="numeric"
                   placeholder={t("EnterValue")}
                   value={ph_value}
                   onChange={(e) => setPhValue(e.target.value)}
                 ></Form.Control>
               </Form.Group>
    </Grid>
    <Grid item xs={0.5}/>
    </Grid>



    <Grid container justifyContent = 'center' style={GridTopStyle} >
 <Grid item xs={2}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button style={BigButtonStyle} onClick={() => SaveData()}>
                    {t("Submit")}
                  </Button>
                </div>
 </Grid>
    </Grid>
    {
   console.log("Nutrent Value is:",nutrentValue)
    }
    </div>)}