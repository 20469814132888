import React, { useState, useRef, useEffect } from "react";
import { alpha, makeStyles, useTheme } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
// import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import SideNavbar from "./SideNavbar";
import camera from "../assets/Camera.png";
import ImgSearchBox from "./ImageSearch";
import Logo from "../HeaderImages/Logo.png";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ShowImgSearch } from "../actions/ShowpageAction";
import { useDispatch, useSelector } from "react-redux";
import { DesktopToolbar } from "./DesktopToolbar";
import { MobileToolbar } from "./MobileToolbar";
import {
  listSearchHistory,
  PushSearchHistory,
} from "../actions/SearchHistoryActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useNavigate } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import "../App.css";
import GA4 from "./GoogleAnalytics4/GA-4";
import cartService from '../services/cart.service';
import { getWishlistCartDetails } from "../actions/cart.action";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    border: "1px solid grey",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    // position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(0, 0, 0, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "35ch",
    },
  },

  MobileInputInput: {
    padding: theme.spacing(0, 0, 0, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "120%",
    [theme.breakpoints.up("md")]: {
      width: "35ch",
    },
  },

  sectionDesktop: {
    display: "none",
    backgroundColor: "grey",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    button: {
      "&:hover": {
        backgroundColor: "blue",
      },
    },
    gutters: {
      marginLeft: 0,
    },
  },
  search2: {
    border: "1px solid grey",
    borderRadius: "6px",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon2: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot2: {
    color: "inherit",
  },
  inputInput2: {
    padding: theme.spacing(0, 0, 0, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "35ch",
    },
  },
}));

export default function PrimarySearchAppBar() {
  let Cam = useRef();
  let MainMenu = useRef();
  let navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const [show, setShow] = useState("none");
  const [logo, setLogo] = useState(true);
  // const [count, setCount] = useState();
  const matches = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();
  const { count } = useSelector(state=>state.wishlistCartDetail)
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const ImageSearchDrop = useSelector((state) => state.ImageSearchDrop);
  const { ImageSearch } = ImageSearchDrop;
  const ImgSearch = () => {
    dispatch(ShowImgSearch(!ImageSearch));
  };
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  useEffect(() => {

  }, []);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>{t("Profile")}</MenuItem>
      <MenuItem onClick={handleMenuClose}>{t("MyAccount")}</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      id={mobileMenuId}
      open={isMobileMenuOpen}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleMobileMenuClose}
    >
      <MobileToolbar handleProfileMenuOpen={handleProfileMenuOpen} />
    </Menu>
  );

  const SearchHistoryList = useSelector((state) => state.SearchHistoryList);
  const { search_history } = SearchHistoryList;
  const [myOptions, setMyOptions] = useState([
    { searched_text: "fertilizers" },
    { searched_text: "Pesticides" },
  ]);
  const onKeyPress = (e) => {
    if (e.charCode === 13 || e.key === "Enter") {
      submitSearchQuery(e.target.value);
      e.preventDefault();
    }
  };

  const getCartWishlistDetails=()=>{
      dispatch(getWishlistCartDetails())
  }

  useEffect(() => {
    const PostingForm = {
      search: [],
    };
    var ActualPostingObj = JSON.stringify(PostingForm);
    if (user) {
      dispatch(listSearchHistory(ActualPostingObj));
    }

    // wishlits and image search
    dispatch(ShowImgSearch(false));
    user && getCartWishlistDetails()
  }, []);

  const getDataFromAPI = (query) => {
    var searcharray = query.split(" ");
    if (query) {
      const PostingForm = {
        search: searcharray,
      };
      var ActualPostingObj = JSON.stringify(PostingForm);
      if (user) {
        dispatch(listSearchHistory(ActualPostingObj));
      }
    }
  };

  const {user} = useSelector((state) => state.auth);

  const submitSearchQuery=async(query)=>{
      let status = await PushSearchHistory(query);
      GA4.search(query);
      if(status === "success"){
        navigate('/search', {state:{query : query}});
      }
      else{
        navigate('/search', {state:{query : query}});
        alert(
          "Your search query cannot be saved in our database due to some internal error from our side but you cansee the results of search"
        );
      }
    }

  function ListItemLink(props) {
    return <ListItem disableGutters={true} button component="a" {...props} />;
  }

  return (
    <div className={classes.grow}>
      <AppBar
        position="static"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{
          color: "black",
          backgroundColor: "transparent",
          boxShadow: "0px 0px 0px 0px",
        }}
      >
        <Toolbar classes={{ root: classes.root }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon/>
            </IconButton>
            <Box style={{ display: logo }}>
              <ListItemLink
                disableTouchRipple="true"
                style={{ backgroundColor: "transparent" }}
                onClick={()=>navigate('/')}
              >
                <img alt="Logo" src={Logo} width="150" height="100%"/>
              </ListItemLink>
            </Box>
          </div>

          {matches ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 0,
              }}
            >
              <Box>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>

                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={(search_history && search_history.recently_searched
                      ? search_history.recently_searched
                      : myOptions
                    ).map((option) => option.searched_text)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onKeyPress={onKeyPress}
                        placeholder={t("Searchbar")}
                        style={{ width: 310, height: 50, textAlign: "center" }}
                        onChange={(e) => getDataFromAPI(e.target.value)}
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />

                  <div>
                    <img
                      alt="cam"
                      ref={Cam}
                      onClick={() => ImgSearch()}
                      src={camera}
                    />
                  </div>
                </div>
              </Box>

              <DesktopToolbar count={count}/>

              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </div>
          ) : (
            //else
            <div style={{ display: "flex" }}>
              <Fade in={true} timeout={400}>
                <div
                  style={{
                    display: show,
                    border: "1px solid grey",
                    position: "relative",
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: alpha(theme.palette.common.white, 0.15),
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.common.white, 0.25),
                    },
                    marginRight: theme.spacing(2),
                    marginLeft: 0,
                    width: "150%",
                    [theme.breakpoints.up("sm")]: {
                      marginLeft: theme.spacing(3),
                      width: "auto",
                    },
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <SearchIcon />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        options={(search_history &&
                        search_history.recently_searched
                          ? search_history.recently_searched
                          : myOptions
                        ).map((option) => option.searched_text)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onKeyPress={onKeyPress}
                            placeholder={t("Searchbar")}
                            style={{
                              width: 220,
                              height: 50,
                              textAlign: "center",
                            }}
                            onChange={(e) => getDataFromAPI(e.target.value)}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        )}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "4%",
                      }}
                    >
                      <img
                        alt="cam"
                        ref={Cam}
                        onClick={() => ImgSearch()}
                        src={camera}
                      />
                    </div>
                  </div>

                  {/* {progress && <LinearProgress />} */}
                </div>
              </Fade>
              <div className={classes.sectionMobile}>
                <IconButton
                  style={{ display: logo }}
                  aria-label="show more"
                  onClick={() => {
                    setShow(true);
                    setLogo("none");
                  }}
                  color="inherit"
                >
                  <SearchIcon />
                </IconButton>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <div ref={MainMenu}>
          <SideNavbar
            MainMenu={MainMenu}
            handleDrawerClose={handleDrawerClose}
          />
        </div>

        <Divider />
      </Drawer>
      {renderMobileMenu}
      {renderMenu}
      {ImageSearch ? <ImgSearchBox Cam={Cam} /> : null}
    </div>
  );
}
