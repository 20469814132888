import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CardContent from "@mui/material/CardContent";
import { Button} from "react-bootstrap";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import {PredictionResetButtonb } from "../../../components/ButtonStyle";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import PredictionCheckPopup from "./PredictionCheckPopup";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GA4 from "../../../components/GoogleAnalytics4/GA-4";
import { useLocation } from "react-router";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: "linear-gradient(to right bottom, #5DA7CA, #BFE2BA)",
  },
}));

export default function PredictionResult() {
  const { t } = useTranslation();
  const location = useLocation()
  const theme = useTheme();
  const xsmall = useMediaQuery(theme.breakpoints.down("xs"));
  const medium = useMediaQuery(theme.breakpoints.up("md"));
  const [crop, setCrop] = useState("");
  const [disease, setDisease] = useState("");
  const [cause, setCause] = useState("");
  const [symptoms, setSymptoms] = useState("");
  const [mgmt, setMgmt] = useState("");
  const [file_name, setFileName] = useState("");
  const [diseasedImg, setDiseasedImg] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [predictionCheckModal, setPredictionCheckModal] = useState(false);

  const {user} = useSelector((state) => state.auth);

  const result = location?.state?.result; //{ "crop" : "Paddy" }//
  console.log(result);

  useEffect(() => {
    if (result) {
      setCrop(result.crop);
      setDisease(result.disease);
      setPercentage(result.percentage);
      setFileName(result.file_name)
      setDiseasedImg(location.state.diseasedImg)
      setPredictionCheckModal(result.status === 'True' ? false : true)
      setCause(result.cause);
      setSymptoms(result.symptom);
      setMgmt(result.mgmt);

      let ga4Data={
        user: user,
        prediction_result: result
      }
      GA4.diseasePrediction(ga4Data)
    }
  }, [result]);

  return (
    <>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        background: "#D6F0FC",
      }}
    >
      <div
        textAlign="center"
        style={{
          width: "80%",
          marginTop: "3%",
          marginBottom: "6vh",
        }}
      >
        <Typography
          component="div"
          variant="h5"
          style={{
            fontSize: 28,
            fontWeight: "bold",
            marginBottom: "2%",
          }}
        >
          {t("result")}
        </Typography>
        <Card style={{ padding: "0.5rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography fontSize={xsmall ? "0.875rem" : "1rem"}>
                {t("cropname")}
              </Typography>
              <Typography fontSize={xsmall ? "0.875rem" : "1.5rem"}>
                {crop}
              </Typography>
            </CardContent>{" "}
            <CardContent sx={{ flex: "1 0 auto" }}>
              {xsmall ? (
                <div>
                  <Typography style={{ fontSize: "0.875rem" }}>
                  {t("diseaseName")}
                  </Typography>
                  <Typography style={{ fontSize: "0.875rem" }}>
                    {/* {result && result.disease} */}
                    {disease}
                  </Typography>
                </div>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography style={{ fontSize: "1rem" }}>
                    {t("diseaseName")}
                    </Typography>
                    <Typography style={{ fontSize: "1.5rem" }}>
                      {/* {result && result.disease} */}
                      {disease}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <div
                      style={{
                        width: "3.75rem",
                        height: "1.875",
                      }}
                    >
                      {percentage ? (
                        <CircularProgressbarWithChildren
                          value={Math.round(percentage)}
                          styles={buildStyles({
                            // Rotation of path and trail, in number of turns (0-1)
                            rotation: 0.25,

                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",
                            pathColor: `linear-gradient(180deg, #5DA7CA 0%, #BFE2BA 100%)`,
                          })}
                        >
                          <div style={{ fontSize: 10, marginTop: -3 }}>
                            <strong>
                              {" "}
                              <text>
                                <text style={{ marginLeft: 12 }}>
                                  {Math.round(percentage)}%
                                </text>
                                <br />
                                {t("accuracy")}
                              </text>{" "}
                            </strong>
                          </div>
                        </CircularProgressbarWithChildren>
                      ) : null}
                    </div>
                  </Grid>
                </Grid>
              )}
            </CardContent>{" "}

            <CardContent sx={{ flex: "1 0 auto" }}>
              <img src={diseasedImg} alt="diseased_image" style={{ height: 100 }} />
            </CardContent>{" "}

          </div>
          <div
            style={{
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              paddingBottom: "0.5rem",
              display: xsmall ? "block" : "none",
            }}
          >
            <Stack direction="row" spacing={2} justifyContent={"space-between"}>
              <Typography fontSize={"0.625rem"}>accuracy</Typography>
              <Typography fontSize={"0.625rem"}>
                {" "}
                {Math.round(percentage)}%{" "}
              </Typography>
            </Stack>
            <BorderLinearProgress
              variant="determinate"
              value={percentage ? Math.round(percentage) : null}
            />
          </div>

          <div
            style={{
              display: xsmall ? "flex" : "none",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Button style={PredictionResetButtonb}>
              <Typography style={{ fontSize: "1rem" }}> Reset</Typography>
            </Button>
          </div>
        </Card>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize={xsmall ? "0.875rem" : "1.5rem"}>
              {t("causeDisease")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize={xsmall ? "0.875rem" : "1rem"}>
              {cause &&
                cause.map((des, index) =>
                  des.length < 1 && des == "" ? null : (
                    <Typography fontSize={xsmall ? "0.875rem" : "1rem"}>
                     {`${index+1}. ${des}`}
                    </Typography>
                  )
                )}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography fontSize={xsmall ? "0.875rem" : "1.5rem"}>
              {t("symptom")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize={18}>
              {symptoms &&
                symptoms.map((des, index) =>
                  des.length < 1 && des == "" ? null : (
                    <Typography fontSize={xsmall ? "0.875rem" : "1rem"}>
                     {`${index+1}. ${des}`}
                    </Typography>
                  )
                )}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography fontSize={xsmall ? "0.875rem" : "1.5rem"}>
              {t("management")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize={xsmall ? "0.875rem" : "1rem"}>
              {mgmt &&
                mgmt.map((des, index) =>
                  des.length < 1 && des == "" ? null : (
                    <Typography fontSize={xsmall ? "0.875rem" : "1rem"}>
                      {`${index+1}. ${des}`}
                    </Typography>
                  )
                )}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>

      {user ? (
              <PredictionCheckPopup
              t={t}
              show={predictionCheckModal}
              onHide={() => setPredictionCheckModal(false)}
              user_id = {user && user.id}
              file_name = {file_name}
              crop = {crop}
              setPredictionCheckModal={setPredictionCheckModal}
            />
            
            ) : null}
    </div>
    
    <div>
    {/* <RelatedProducts param={disease} /> */}
  </div>
  </>
  );
}
