import React, { useState, useEffect } from "react";
import "../App.css";
import Loader from "../components/Loader";
import { Button, Form } from "react-bootstrap";
import CheckoutSteps from "../components/CheckoutSteps";
import { MediumButtonStyle } from "../components/ButtonStyle";
import { useTranslation } from "react-i18next";
import paymentService from "../services/payment.service";
import orderService from "../services/order.service";
import { useSelector } from "react-redux";
import cartService from "../services/cart.service";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import rupay from '../assets/Rupay.png';
import mastercard from '../assets/mastercard.png';
import visa from '../assets/Visa.png';

function PaymentScreen() {
  const navigate = useNavigate()
  const location = useLocation()
  const {user} = useSelector((state) => state.auth);
  const RAZORPAY_KEY_ID = process.env.REACT_APP_AXIS_RAZORPAY_PROD_KEY_ID;
  const { t } = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState();
  const { addressId, shippingCharge, totalAmount } = location?.state ?? {} 

  const paymentHandler=async(orderId)=>{

    const cleanCart=()=>{
      cartService.removeAllFromCartHandler()
                    .then((res)=>{
                      console.log('removeAllFromCartHandler :: ',res.status)
                    })
                    .catch((error)=>{
                      console.log(error)
                    })
    }

    const confirmOrder=(data)=>{
        paymentService.completeOrder(data)
                      .then((res)=>{
                        console.log("completeOrder-response :: ",res)
                        return res.status
                      })
                      .then((status)=>{
                        if(status==200){
                          orderService.confirmOrderHandler(orderId)
                                        .then((res)=>{
                                            console.log(res.data)
                                            alert(`Success: ${data.razorpay_payment_id}`);
                                            cleanCart()
                                            navigate('/confirmation', {state:{orderId : orderId}})
                                        })
                                        .catch((error)=>{
                                            console.log(error)
                                        })
                        }
                        else{
                          alert('something went wrong. if your money is deducted then you will be refunded within 2-3 business days.111 ')
                        }
                      })
                      .catch((error)=>{
                        console.log(error)
                        failedOrder(orderId)
                        alert('something went wrong. if your money is deducted then you will be refunded within 2-3 business days.222 ')
                      })

      }

    const failedOrder=(orderId)=>{
        orderService.failedOrderHandler(orderId)
                      .then((res)=>{
                      console.log(res.data)
                      })
                      .catch((error)=>{
                      console.log(error)
                      })
    }

    let options = {
        description: 'Order Transaction',
        image: 'https://storage.googleapis.com/cropway_user_uploaded_files/payment_usage/ic_launcher.png',
        currency: 'INR',
        key: RAZORPAY_KEY_ID, // Your api key
        amount: totalAmount * 100, //totalPrice
        name: 'Cropway',
        order_id: orderId, //'order_DslnoIgkIDL8Zt' Replace this with an order_id created using Orders API.
        prefill: {
          email: user.email,
          contact: user.phone,
          name: user.first_name
        },
        theme: {color: '#bfe2ba'},
        handler: async function (response) {
            let razordata = {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              order_id: orderId,
              };
        
        // for order confirmation and save
        confirmOrder(razordata)
        }
    }

    let rzp = new window.Razorpay(options);
    rzp.open();
    rzp.on('payment.failed', function (response){
      failedOrder(orderId)
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
  }

  const dataUpload=(orderId)=>{
    let data = {
      orderId : orderId,
      addressId : addressId,
      deliveryCharge : shippingCharge,
      totalAmount : totalAmount,
    }

    orderService.postOrderData(data)
                .then((res)=>{
                  return res.data.order_id
                })
                .then((orderId)=>{
                  paymentHandler(orderId)
                })
                .catch((error)=>{
                  console.log('Error :: ', error)
                })
  }

  const createOrder=()=>{
    let data={
      amount : totalAmount * 100,
    }
    paymentService.InitiateOrder(data)
                  .then((res)=>{
                    return res.data.order_id
                  })
                  .then((orderId)=>{
                    dataUpload(orderId)
                  })
                  .catch((error)=>{
                    console.log(error)
                  })
  }

  const submit=(e)=>{
    e.preventDefault()
    createOrder()
  }

  useEffect(() => {
    if(addressId && shippingCharge && totalAmount){
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      document.body.appendChild(script);
    }
    else{
      navigate('/shipping')
    }
  }, []);

  // ---------------component--------------------

  const TotalAmount=()=>{
    return(
      <div style={{background:'rgba(244, 246, 251, 1)', padding:10, margin:10, borderRadius:5}}>
        <div style={{display:'flex',alignItems:'center', margin:10}}>
          <text style={{width:'50%'}}>
            Total Amount
          </text>
          <text style={{width:'50%'}}>
            {totalAmount}
          </text>
        </div>
      </div>
    )
  }

  return (
      <div>
         <h1 style={{ fontWeight: "bold", color: "black" }}>
           {t("PaymentMethod")}
         </h1>
         <CheckoutSteps step2 />
         <Form onSubmit={submit}>
          <div style={{display:'flex', margin:10}}>
              <div style={{width:'60%', height:400, margin:10, padding:10, background:'rgba(244, 246, 251, 1)'}}>
                  <strong style={{color:'black'}}>Select Payment Method</strong>
                    <Form.Check
                      type="radio"
                      required
                      style={{margin:10}}
                      value={'razorpay'}
                      checked={paymentMethod}
                      onChange={(e)=>paymentMethod ? setPaymentMethod() : setPaymentMethod(e.target.value)}
                      id="select_payment_method"
                      name="select_payment_method"
                      label="Online payment(Credit Card/ Debit Card/ Netbanking/ UPI)"
                    />
                    <img src={rupay}/>
                    <img src={mastercard}/>
                    <img src={visa}/>
              </div>
            <div style={{width:'40%'}}>
              <div className="address-amount-detail" >
                  <TotalAmount/>
              </div>
            </div>
          </div>
          <div style={{display:'flex', justifyContent:'right',alignItems:'center', margin:10, padding:10, background:'rgba(244, 246, 251, 1)'}}>
              <Button
                type="submit"
                style={{ ...MediumButtonStyle}}
                className="m-4"
              >
                {t("Proceed To Checkout")}
              </Button>
          </div>
        </Form>
      </div>
  );
}

export default PaymentScreen;
